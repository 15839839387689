import React, { useContext, useEffect, useState } from "react"
import Slider from "react-slick"
import { useRecoilState } from "recoil"
import styled from "styled-components"

import modalAtom from "~atoms/modalAtom"
import newsAtom from "~atoms/newsAtom"
import ButtonWithRoundedEdges from "~components/ButtonWithRoundedEdges"
import Hidden from "~components/Hidden"
import Container from "~layout/Container"

import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"
import SEO from "../../components/Seo"
import ModalContext from "../../contexts/ModalContext"
import NewsContactDetails from "../../layouts/NewsContactDetails"

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 120px;
    margin-bottom: 50px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    margin-top: 80px;
    margin-bottom: 50px;
  }
`

const NewsWrapper = styled.div`
  padding: 40px 30px;

  @media ${({ theme }) => theme.breakpointDown.md} {
    padding: 20px 17px;

    padding-bottom: 80px;
  }
`

const PageHeader = styled.div`
  color: #003d6a;
  max-width: 232px;
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 600;
  line-height: 33px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 22px;
    margin-bottom: 56px;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    max-width: 311px;
  }
`
const StyledCol = styled.div`
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11);
  padding: 0;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  height: 646px;
  @media ${({ theme }) => theme.breakpointDown.md} {
    height: auto;
  }
  position: relative;
`
const NewsImg = styled.img`
  position: relative;
  object-fit: cover;
  object-position: center;
  display: block;
  height: 321px;
  @media ${({ theme }) => theme.breakpointDown.md} {
    height: 200px;
  }
  width: 100%;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin-bottom: 0;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.26);
`
const NewsTitle = styled.div`
  color: #003d6a;
  font-family: "Palanquin";

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  @media ${({ theme }) => theme.breakpointDown.md} {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }
  margin-bottom: 20px;
`
const NewsDate = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  max-width: 160px;
`
const NewsLocation = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 25px;
`
const NewsInfoFlex = styled.div`
  display: flex;
  margin-bottom: 19px;
`
const NewsExcerpt = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 40px;
  @media ${({ theme }) => theme.breakpointDown.md} {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 250;
    line-height: 20px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
const ReadMore = styled(ButtonWithRoundedEdges)`
  position: absolute;
  bottom: 31px;
  left: 31px;
  @media ${({ theme }) => theme.breakpointDown.md} {
    left: 15px;
  }
`
const StyledPagination = styled(Pagination)`
  display: none;
  @media ${({ theme }) => theme.breakpoint.md} {
    display: block;
  }
  color: #003d6a;
  font-family: Palanquin;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
`

const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1.3,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 10000,
  draggable: true,
  accessibility: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick",
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "30px",
        slidesToScroll: 1,
        infinite: true,
        // dots: true,
      },
    },
  ],
}

const StyledSlider = styled(Slider)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    overflow: hidden;
    .slick-list {
      overflow: visible;
    }
    .slick-track {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
    }
    width: 100%;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    overflow: visible;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 28px;

    @media ${({ theme }) => theme.breakpoint.md} {
      grid-template-columns: repeat(1, minmax(300px, 1fr));
      row-gap: 44px;
    }

    @media ${({ theme }) => theme.breakpoint.lg} {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
      row-gap: 54px;
    }
  }
`
const StyledContainer = styled(Container)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    padding: 0;
  }
`

function getOrdinalNum(n) {
  return (
    n +
    (n > 0
      ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : "")
  )
}

const NewsItem = ({ post }) => {
  const [currentNew, setCurrentNews] = useRecoilState(newsAtom)
  const [modal, setModal] = useRecoilState(newsAtom)
  const modalContext = useContext(ModalContext)

  const onTermsClickHandler = e => {
    e.preventDefault()
    modalContext.modalDispatch("open")
  }

  useEffect(() => {
    const modalSettings = { normal: false, title: "", content: "" }
    setModal(modalSettings)
  }, [])

  const { uri, title, featuredImage, newsItem } = post
  let date = new Date(post.date)
  var mm = date.toLocaleString("default", { month: "long" })
  var dd = date.getDate()
  var yy = date.getFullYear()

  dd = getOrdinalNum(dd)

  date = dd + " " + mm + " " + yy

  const { city, country, excerpt } = newsItem
  return (
    <StyledCol key={post.databaseId}>
      <NewsImg
        src={featuredImage?.node?.sourceUrl}
        alt={featuredImage?.node?.altText}
      />
      <NewsWrapper>
        <NewsTitle>{title}</NewsTitle>
        <NewsInfoFlex>
          <NewsDate>{date}</NewsDate>
          <NewsLocation>
            {city}
            {city !== null && country !== null ? ", " : null}
            {country}
          </NewsLocation>
        </NewsInfoFlex>
        <NewsExcerpt dangerouslySetInnerHTML={{ __html: excerpt }} />

        <ReadMore
          color="#eb7c00"
          borderColor="#eb7c00"
          onClick={e => {
            setCurrentNews(post)
            onTermsClickHandler(e)
          }}
          initialText="Read More"
        />
      </NewsWrapper>
    </StyledCol>
  )
}

const NewsIndex = ({ pageContext }) => {
  const [buttonState, setButtonState] = useState(false)
  const [allPostsState, setAllPostsState] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [nextPage, setNextPage] = useState(true)
  const [prevPage, setPrevPage] = useState(false)
  const [currentPosts, setCurrentPosts] = useState([])
  const [category, setCategory] = useState("News")
  let { nodes, pageNumber, hasNextPage, itemsPerPage, allPosts } = pageContext

  useEffect(() => {
    setCurrentPosts(nodes)
    setAllPostsState(allPosts)
  }, [])

  useEffect(() => {
    // console.log(allPostsState.length)
    if (currentPage > Math.floor(allPostsState.length / 6) && currentPage > 1) {
      setNextPage(false)
    }
    if (
      currentPage < Math.floor(allPostsState.length / 6) ||
      (allPostsState.length > 6 && currentPage == 1)
    ) {
      setNextPage(true)
    }
    if (currentPage == 1) {
      setPrevPage(false)
    }
    if (currentPage > 1) {
      setPrevPage(true)
    }
    if (allPostsState.length <= 6) {
      setNextPage(false)
    }
  }, [currentPage, allPostsState])

  useEffect(() => {
    setCurrentPage(1)
    paginate(1)
  }, [allPostsState])

  // useEffect(() => {
  //   if (buttonState) {
  // const FilteredAllPosts = allPostsState.filter(
  //   post => post.newsItem.categoryField == "Press Releases"
  // )
  // setAllPostsState(FilteredAllPosts)
  //   }
  //   if (!buttonState) {
  //     setAllPostsState(allPosts)
  //   }
  // }, [buttonState])

  useEffect(() => {
    const FilteredAllPosts = allPosts.filter(
      post => post.newsItem.categoryField == category
    )
    // console.log(FilteredAllPosts)
    setAllPostsState(FilteredAllPosts)
  }, [category])

  const paginate = pageNumber => {
    setCurrentPosts(allPostsState.slice((pageNumber - 1) * 6, pageNumber * 6))
  }

  return (
    <Layout>
      <SEO title="News" description="News articles" keywords={[`news`]} />
      <Wrapper>
        <Container overflowDisabled>
          <PageHeader>Pradera News and Media</PageHeader>
        </Container>
        <Container overflowDisabled style={{ marginBottom: 40 }}>
          Filter by:
          <ButtonWithRoundedEdges
            color={category == "News" ? "#eb7c00" : "#c8c9c7"}
            id="scrollTopBtn"
            onClick={() => {
              setCategory("News")
              setButtonState(!buttonState)
            }}
            initialText="News"
            style={{ marginLeft: 20 }}
          />
          <ButtonWithRoundedEdges
            color={category == "Press Releases" ? "#eb7c00" : "#c8c9c7"}
            id="scrollTopBtn"
            onClick={() => {
              setCategory("Press Releases")
              setButtonState(!buttonState)
            }}
            initialText="Press Releases"
            style={{ marginLeft: 20 }}
          />
        </Container>
        <Hidden smDown>
          <StyledContainer overflowDisabled>
            <StyledSlider {...settings}>
              {/* {nodes && nodes.map(post => <NewsItem post={post} />)} */}
              {currentPosts &&
                currentPosts.map((post, index) => (
                  <NewsItem key={index} post={post} />
                ))}
            </StyledSlider>

            <StyledPagination
              prevPage={prevPage}
              nextPage={nextPage}
              currentPage={currentPage}
              paginate={paginate}
              setCurrentPage={setCurrentPage}
            />
          </StyledContainer>
        </Hidden>
        <Hidden mdUp>
          <StyledContainer overflowDisabled>
            <StyledSlider {...settings}>
              {allPosts &&
                allPosts.map((post, index) => (
                  <NewsItem key={index} post={post} />
                ))}
            </StyledSlider>
            <StyledPagination
              prevPage={prevPage}
              nextPage={nextPage}
              currentPage={currentPage}
              paginate={paginate}
              setCurrentPage={setCurrentPage}
            />
          </StyledContainer>
        </Hidden>
      </Wrapper>
      <NewsContactDetails />
    </Layout>
  )
}

export default NewsIndex
