import { Box } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import { blogURI } from "../../globals"
import RightArrowPagination from "../images/right-arrow-pagination.svg"

const Wrapper = styled.div`
  margin: 33px 0px;
  a:link {
    color: red;
  }
`

const PaginationText = styled.div`
  color: #003d6a;
  font-family: "Palanquin";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
`
const LeftArrowSVG = styled.div`
  float: left;
  background-color: #003d6a;
  border-radius: 50%;
  color: white;
  width: 25px;
  height: 25px;
  margin-right: 16px;
`

const RightArrowSVG = styled.div`
  float: right;
  border-radius: 50%;
  color: white;
  width: 25px;
  height: 25px;
  margin-left: 16px;
`

const Pagination = ({
  prevPage,
  nextPage,
  hasNextPage,
  paginate,
  setCurrentPage,
  currentPage,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Row justify="flex-start" alignItems="flex-start">
        <Col xs={6}>
          {prevPage && (
            <a
              style={{ textDecoration: "none" }}
              href="#"
              onClick={() => {
                paginate(currentPage - 1)
                setCurrentPage(currentPage - 1)
              }}
            >
              <PaginationText>
                <Box
                  display="flex"
                  spacing={3}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box>
                    <LeftArrowSVG>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </LeftArrowSVG>
                  </Box>
                  <Box xs={6}>Back to recent news stories</Box>
                </Box>
              </PaginationText>
            </a>
          )}
        </Col>

        <Col xs={6}>
          {nextPage && (
            <a
              style={{ textDecoration: "none" }}
              href="#"
              onClick={() => {
                paginate(currentPage + 1)
                setCurrentPage(currentPage + 1)
              }}
            >
              <PaginationText>
                <Box
                  display="flex"
                  spacing={3}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box>View previous six news stories</Box>
                  <Box>
                    <RightArrowSVG>
                      <img src={RightArrowPagination} alt="right arrow" />
                    </RightArrowSVG>
                  </Box>
                </Box>
              </PaginationText>
            </a>
          )}
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Pagination
