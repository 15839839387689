import React from "react"
import styled from "styled-components"
import Container from "~layout/Container"
import NewsContactDetailsImg from "../../images/NewsContactDetails.jpg"

const ContactSection = styled.section`
  margin-bottom: 37px;
  margin-top: 37px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 66px;
  }
`

const ContactImg = styled.div`
  width: auto;
  height: 314px;
  background-position: center;
  background-size: cover;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11);
  position: relative;
  background-image: url(${NewsContactDetailsImg});
  &:after {
    content: "";
    position: absolute;
    width: auto;
    height: 314px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 22px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 10%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.7) 70%,
      rgba(0, 0, 0, 0.85) 100%
    );
    z-index: 1;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    background-position: center -55px;
  }
`

const ContactDetailsContent = styled.div`
  padding: 10px 10px;
  max-width: 900px;
  margin: 0 auto;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 40px 20px;
  }
`

const FlexBox = styled.div`
  width: 100%;
  height: 90%;

  @media ${({ theme }) => theme.breakpoint.md} {
    width: 100%;
    height: inherit;
  }
`

const Title = styled.div`
  display: none;
  color: #ffffff;
  font-family: Palanquin;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  display: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    display: block;
  }
`

const EnquiriesBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  @media ${({ theme }) => theme.breakpoint.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const EnquiriesBoxElement = styled.div`
  padding: 10px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 20px;
  }
`

const EnquiriesTitle = styled.div`
  color: #ffffff;
  font-family: Palanquin;
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-bottom: 14px;
`

const ContactName = styled.a`
  display: block;
  color: #ffffff;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-bottom: 2px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
  }
`

const MB65 = styled.div`
  margin-bottom: 65px;
`

const ContactEmail = styled.a`
  display: block;
  color: #ffffff;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
  }
`

const NewsContactDetails = () => {
  return (
    <ContactSection>
      <MB65>
        <Container overflowDisabled>
          <ContactImg>
            <ContactDetailsContent>
              <FlexBox>
                <Title>Contact Details</Title>
                <EnquiriesBox>
                  <EnquiriesBoxElement>
                    <EnquiriesTitle>Company enquiries:</EnquiriesTitle>
                    <ContactName>Colin Campbell</ContactName>
                    <ContactEmail
                      href="mailto:colin.campbell@pradera.com"
                      target="_blank"
                      className="no-link"
                    >
                      colin.campbell@pradera.com
                    </ContactEmail>
                  </EnquiriesBoxElement>
                  <EnquiriesBoxElement>
                    <EnquiriesTitle>Press enquiries:</EnquiriesTitle>
                    <ContactName>Marina Ferri</ContactName>
                    <ContactEmail
                      href="mailto:marina.ferri@pradera.com"
                      target="_blank"
                      className="no-link"
                    >
                      marina.ferri@pradera.com
                    </ContactEmail>
                  </EnquiriesBoxElement>
                </EnquiriesBox>
              </FlexBox>
            </ContactDetailsContent>
          </ContactImg>
        </Container>
      </MB65>
    </ContactSection>
  )
}

export default NewsContactDetails
